<template>
    <div class="event row">
        <div class="thumb" :style="eventInfo.thumbnail ? {'background-image': `url(${eventInfo.thumbnail})`} : {}  " />
        <div class="ml-3 side-content">
            <div class="title d-flex align-items-start justify-content-between w-100">
                <div>
                    <router-link :to="`/events/${eventInfo.id}`" class="title">
                        <h4 class="fw-bold mb-1">{{ eventInfo.title }}</h4>
                    </router-link>
                    <p class="text-muted">{{ eventInfo.description }}</p>
                </div>
                <router-link :to="`/events/${eventInfo.id}`">
                    <button class="ml-2 main-outlined">{{ t('see') }}</button>
                </router-link>
            </div>
            <div class="row align-items-center bottom mr-1">
                <a 
                    :href="`/company/${eventInfo.company.id}`"
                    @click.prevent="openPage(`/company/${eventInfo.company.id}`)"
                    class="d-flex align-items-center company"
                >
                    <div class="profile-pic" :style="eventInfo.company.avatar ? {'background-image': `url(${eventInfo.company.avatar})`} : {}" />
                    <h5 class="mb-0 ml-2 text-dark">{{ eventInfo.company.name }}</h5>
                    <b-icon-check-circle-fill class="verified ml-2" variant="primary" />
                </a>
                <div class="date d-flex align-items-center">
                    <img src="@/assets/calendar.png" alt="">
                    <span 
                        :class="['ml-2', type == 'upcoming' ? 'text-primary' : type == 'ongoing' ? 'text-success' : 'text-warning']"
                    >{{ parseDateTime(eventInfo.start_at) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'eventInfo', 'type' ],
    methods: {
        parseDateTime(newDate) {
            const dateTime = new Date(String(newDate).replace(' ', 'T'))
            let date = dateTime.getDate()
            date = date / 10 >= 1 ? date : `0${date}`
            let month = dateTime.getMonth() + 1
            month = month / 10 >= 1 ? month : `0${month}`
            const year = dateTime.getFullYear()
            const hours = dateTime.getHours()
            const minutes = dateTime.getMinutes()
            return `${date}-${month}-${year}  ${hours}:${minutes >= 10 ? minutes : `0${minutes}`}` 
        },
        openPage(href){ 
            if(!this.$store.state.loggedIn) {
                this.warning(this.t('login2SeeAuthor'))
                return;
            }
            this.$router.push(href)
        },
    },
}
</script>

<style scoped>
    .event {
        max-width: 100%;
        margin: 15px 0 !important;
        background-color: white;
        box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        padding: 20px;
        backdrop-filter: blur(32px);
        border-radius: 5px;
        width: 790px;
    }
    .thumb {
        width: 100px;
        height: 100px;
        background-color: grey;
        background-position: center;
        background-size: cover;
        border-radius: 5px;
    }
    .title {
        color: black !important;
        text-decoration-color: rgba(0, 0, 0, 0.2) !important;
    }
    .side-content {
        width: calc(100% - 120px);
    }

    .main-outlined {
        font-size: 14px;
        padding: 4px 15px;
    }
    .profile-pic {
        min-width: 40px;
        min-height: 40px;
        height: 40px;
        width: 40px;
    }
    .verified {
        font-size: 16px;
    }
    .bottom {
        margin-left: 0px;
    }
    .company {
        border-radius: 20px;
        padding: 3px 10px;
        padding-left: 0;
        margin-left: 0px !important;
        margin: -3px -10px;
        width: max-content;
    }
    .company:hover {
        background-color: rgba(128, 128, 128, 0.075);
    }
    .date {
        font-weight: 600;
        font-size: 14px;
        margin-left: 10%;
    }
    .date img {
        width: 20px;
    }
    @media (max-width: 540px) {
        .event {
            margin-top: 8px !important;
            margin-bottom: 2px !important;
            padding: 10px;
            border-radius: 0 !important;
        }
        .title {
            flex-direction: column;
        }
        .thumb {
            margin-left: -10px;
            margin-right: -10px;
            margin-top: -5px;
            width: calc(100% + 20px);
            height: 90vw;
            border-radius: 0 !important;
        }
        .side-content {
            margin-left: 0 !important;
            margin-top: 20px;
            width: 100%;
        }
        .main-outlined {
            margin-left: 0 !important;
        }
        .bottom {
            align-items: center !important;
            margin-top: 20px;
        }
        .date {
            margin-left: 5% !important;
        }
    }
</style>