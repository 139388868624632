<template>
    <div class="home">
        <left-sidebar />
        <div class="mt-3 main">
            <div class="mb-5" v-if="upcoming.length">
                <h4>{{ t('futureEvents') }}</h4>
                <event 
                    v-for="event in upcoming"
                    :key="'event ' + event.id"
                    :eventInfo="event"
                    type='upcoming'
                />
            </div>
            <div class="mb-5" v-if="ongoing.length">
                <h4>{{ t('currentEvents') }}</h4>
                <event 
                    v-for="event in ongoing"
                    :key="'event ' + event.id"
                    :eventInfo="event"
                    type='ongoing'
                />
            </div>
            <div class="mb-5" v-if="finished.length">
                <h4>{{ t('finishedEvents') }}</h4>
                <event 
                    v-for="event in finished"
                    :key="'event ' + event.id"
                    :eventInfo="event"
                    type='finished'
                />
            </div>
            <infinite-loading @infinite="infiniteHandler">
                <div slot="spinner"><b-spinner class="m-4" variant="success"></b-spinner></div>
                <div slot="no-more" class="m-4 fw-bold">{{ t('noMoreEvents') }} <b-icon-emoji-smile-upside-down /> </div>
                <div slot="no-results" class="m-4 fw-bold">{{ t('eventsNotFound') }} <b-icon-emoji-neutral /> </div>
            </infinite-loading>
        </div>
        <right-sidebar />
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import leftSidebar from '@/components/LeftSidebar';
import rightSidebar from '@/components/RightSidebar';
import Event from '../../components/Event.vue';

export default {
    name: 'EventsList',
    data() {
        return {
            eventsList: [],
            page: 0,
        }
    },
    mounted() {
        document.title = this.t('events')
    },
    computed: {
        upcoming() {
            return this.eventsList.filter(event => {
                const startAt = event.start_at.replace(' ', 'T')
                return new Date(startAt).getTime() > Date.now()
            })
        },
        ongoing() {
            return this.eventsList.filter(event => {
                const startAt = event.start_at.replace(' ', 'T')
                const endAt = event.end_at.replace(' ', 'T')
                return new Date(endAt).getTime() > Date.now() && new Date(startAt).getTime() < Date.now()
            })
        },
        finished() {
            return this.eventsList.filter(event => {
                const endAt = event.end_at.replace(' ', 'T')
                return new Date(endAt).getTime() < Date.now()
            })
        }
    },
    methods: {
        async infiniteHandler($state) {
            this.page++
            this.getEvents($state)
        },
        async getEvents($state) {
            const events = await this.$store.dispatch('getEventsList', {
                page: this.page,
                locale: this.currLang,
            })
            if(events.length) {
                this.eventsList = events
                $state.loaded()
            } else {
                $state.complete()
            }
        }
    },
    components: {
        InfiniteLoading,
        leftSidebar,
        rightSidebar,
        Event,
    }
}
</script>

<style scoped>
</style>